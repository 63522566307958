import React, { useState } from 'react';
import userbase from 'userbase-js';

export default function Login(props: any) {
  const {loginComplete} = props;
  const [busy, setBusy] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');

  return <div id="auth-view">
  <h1>Login</h1>
  <form onSubmit={(e:any)=>{
    setBusy(()=>true)
    e.preventDefault();
    userbase.signIn({ username, password, rememberMe: 'local' })
     .then((user)=>loginComplete(user))
     .catch((e)=>{
       setLoginError(e.message)
       setBusy(()=>false)
      })
  }}>
    <div>
      <input defaultValue={username} onInput={e => setUsername((e.target as any).value)} placeholder="Username"/>
    </div>
    <div>
      <input defaultValue={password} type="password" onInput={e => setPassword((e.target as any).value)} placeholder="Password"/>
    </div>
    <input type="submit" value="Login" disabled={busy}/>
  </form>
  <div id="login-error">{loginError}</div>
</div>;
}