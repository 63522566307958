import React, { useState } from "react";
import userbase from "userbase-js";
import { LinkButton } from "./common";

interface LinkModel {
  itemId: string;
  item: LinkInfo;
}

interface LinkInfo {
  url: string;
  name: string;
  visits: number;
  lastVisit?: Date;
  added: Date;
  section: string;
}

function AddLinkPage(props: any) {
  const { done } = props;
  const [url, setUrl] = useState("url");
  const [name, setName] = useState("name");
  const [section, setSection] = useState("section");

  return (
    <>
      <form
        id="add-link-form"
        onSubmit={() => {
          let newLink: LinkInfo = {
            url,
            name,
            section,
            visits: 0,
            added: new Date(),
            lastVisit: undefined,
          };

          userbase
            .insertItem({ databaseName: "links", item: newLink })
            .then(() => {
              console.log("Inserted");
              done();
            })
            .catch((err: Error) => console.error(err));
        }}
      >
        <div>
          <input
            defaultValue={url}
            onInput={(e) => setUrl((e.target as any).value)}
            placeholder="Username"
          />
        </div>
        <div>
          <input
            defaultValue={name}
            onInput={(e) => setName((e.target as any).value)}
            placeholder="Password"
          />
        </div>
        <div>
          <input
            defaultValue={section}
            onInput={(e) => setSection((e.target as any).value)}
            placeholder="Username"
          />
        </div>
        <input type="submit" value="Add Link" />
      </form>

      <LinkButton label="Cancel" onClick={done} />
    </>
  );
}

function LinkList(props: any) {
  const { links } = props;

  // Split up into sections
  const sections: any = {};
  links.forEach((entry: any) => {
    const sectionName = entry.item.section ?? "Links";
    if (sections[sectionName] == null) {
      sections[sectionName] = [];
    }

    sections[sectionName].push(entry);
  });

  const sectionNames: string[] = Object.keys(sections).sort();

  function sectionBody(name: string) {
    return (
      <>
        {
          sections[name].map((entry: any) => {
            return (
              <span key={entry.itemId}>
                <a
                  href="#self"
                  className="linkyLink"
                  onClick={(e) => {
                    if (entry.clicks == null) {
                      entry.clicks = 1;
                    } else {
                      entry.clicks = entry.clicks + 1;
                    }
                    e.preventDefault();

                    // open an empty window
                    console.log("opening tab");
                    const tab = window.open("about:blank");
                    userbase
                      .updateItem({
                        databaseName: "links",
                        itemId: entry.itemId,
                        item: entry.item,
                      })
                      .then(() => {
                        if (tab != null) {
                          tab.location = entry.item.url;
                          tab.focus();
                        } else {
                          window.location.href = entry.item.url;
                        }
                      })
                      .catch((e: any) => console.error(e));

                    // updateItem()
                    // entry.clicks = entry.clicks + 1;
                    // e.preventDefault();
                    // window.open(entry.item.url, '_blank');
                  }}
                >
                  {entry.item.name}
                </a>
              </span>
            );
          })
          // JSON.stringify(sections[name])
        }
      </>
    );
  }

  return (
    <table className="linkTable">
      <tbody>
        {sectionNames.map((sectionName: string, index: number) => {
          return (
            <tr key={index}>
              <td className="linkListTableCell">{sectionName} →</td>
              <td>{sectionBody(sectionName)}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

function EditList(props: any) {
  const { links } = props;

  const deleteLink = (link: any) => {
    userbase
      .deleteItem({ databaseName: "links", itemId: link.itemId })
      .then(() => {
        console.info("Link deleted");
      })
      .catch((e: any) => console.error(e));
  };

  return (
    <>
      {links.map((entry: any) => {
        return (
          <div key={entry.itemId}>
            <a
              href="#/"
              onClick={() => {
                window.location = entry.item.url;
              }}
            >
              {entry.item.name} : {entry.item.url}
            </a>{" "}
            <a href="#/" onClick={() => deleteLink(entry)}>
              <img className="smallicon" alt="Delete" src="delete-24px.svg" />
            </a>
          </div>
        );
      })}
    </>
  );
}

function Linky(props: any) {
  const [page, setPage] = useState("links");
  const { state } = props;
  const { linksDB } = state;

  const handleAddRecord = () => {
    setPage("addLink");
  };

  const handleEditAll = () => {
    setPage("editlinks");
  };

  const handleDoneEditing = () => {
    setPage("links");
  };

  return (
    <>
      <h3>My Links</h3>
      <div>
        {page === "addLink" && <AddLinkPage done={() => setPage("links")} />}
        {page === "links" && (
          <>
            <LinkList links={linksDB} />
            <hr></hr>
            <LinkButton label="Add a Link" onClick={handleAddRecord} />
            <br />
            <LinkButton label="Edit All" onClick={handleEditAll} />
          </>
        )}
        {page === "editlinks" && (
          <>
            <EditList links={linksDB} />
            <hr></hr>
            <LinkButton label="Done Editing" onClick={handleDoneEditing} />
          </>
        )}
      </div>
    </>
  );
}

export default Linky;
