import React, { useEffect, useState, useContext } from 'react';
import './App.css';
import userbase from 'userbase-js';
import Linky from './Linky';
import { LinkButton } from './common';
import { LoginOrSignUp, loginComplete } from './components/LoginOrSignUp'

interface IUser {
  username: string
  userId: string
}

interface IUserState {
  user?: IUser
}

const UserContext = React.createContext<IUserState>({});

function UserInfo(props:any) {
  const { setState } = props;
  const user = useContext(UserContext).user;

  const handleSignOut = ()=>{
    userbase.signOut()
      .then(()=>setState((state:any)=>({
        ...state, userState: {}, page: 'login'
      })));
  };

  return <>
    {user?.username || 'not logged in'}<br/>
    {user != null && <LinkButton label="Sign Out" onClick={handleSignOut}/>}
  </>
}

function App() {
  const [state, setState] = useState({
    page: 'prelogin',
    userState: {} as IUserState,
    autoLoginAttempted: false,
    linksDB: []
  });
  
  console.log("App State", state);

  if (!state.autoLoginAttempted) {
    setState((state:any)=>({...state, autoLoginAttempted: true}));

    userbase.init({ appId: '77c2df09-34c6-4908-b230-ef087abf6b67' })
    .then((session)=>{
      if (session && session.user) {
        console.log("Logged In", session.user);
        loginComplete(state, setState, session.user);
      } else {
        console.log("Autologin failed");
        setState((state:any)=>({...state, prelogin:false, page: 'login'}));
      }
    })
    .catch((err)=>{console.error(err)});
  }

  const {page, userState} = state;

  useEffect(() => {
  }, []);

  return (
    <UserContext.Provider value={{user: userState.user}}>
      <div className="App">
        <div className="App-header">
          <div className="same-line">
            <div className="on-the-left" >{page}</div>
            <div className="on-the-center" ><span className="headerLabel">Linky 2 <span aria-label="Rocket" role="img">🚀</span></span></div>
            <div className="on-the-right" ><UserInfo state={state} setState={setState} /></div>
          </div>
        </div>

        {userState.user == null && <LoginOrSignUp state={state} setState={setState} />}
        {userState.user != null && <Linky state={state} setState={setState} />}
      </div>
    </UserContext.Provider>
  );
}

export default App;
