import React from 'react';
import userbase from 'userbase-js';
import { LinkButton } from '../common';
import SignUp from './SignUp';
import Login from './Login';

export const loginComplete = (state:any, setState:any, user:any)=>{
  setState((state:any)=>({...state, userState: {user}, page: 'home'}));
  userbase.openDatabase({databaseName:'links', changeHandler:(linksDB)=>{
    setState((state:any)=>({...state, linksDB}));
  }})
    .then((db)=>{
      console.log("Database is open");
    })
    .catch((err)=>{
      console.error("Database error", err);
    })
};

export function LoginOrSignUp(props:any) {
  const { state, setState } = props;

  const handleSignUpClick = ()=>{
    setState((state:any)=>({
      ...state, page: 'signup'
    }));
  };

  const handleLoginClick = ()=>{
    setState((state:any)=>({
      ...state, page: 'login'
    }));
  };

  return <>
    { state.page === 'signup' && <SignUp signupComplete={(user:any)=>loginComplete(state, setState, user)}/> }
    { state.page === 'login' && <Login loginComplete={(user:any)=>loginComplete(state, setState, user)}/> }

    <br/>
    { state.page === 'login' && 
      <div>Need an account?  <LinkButton label="Sign Up" onClick={handleSignUpClick}/></div>
    }
    { state.page === 'signup' && 
      <div>Already have an account?  <LinkButton label="Log In" onClick={handleLoginClick}/></div>
    } 
  </>;
}

