import React, { useState } from 'react';
import userbase from 'userbase-js';

export default function SignUp(props: any) {
  const {signupComplete} = props;
  const [busy, setBusy] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  return <div id="auth-view">
  <h1>Create an account</h1>
  <form id="signup-form" onSubmit={(e:any)=>{
    setBusy(()=>true);
    e.preventDefault();
    userbase.signUp({ username, password, rememberMe: 'local' })
     .then((user)=>signupComplete(user))
     .catch((e)=>{
       setErrorMessage(()=>e.message);
       setBusy(()=>false);       
     })
  }}>
    <div>
      <input defaultValue={username} onInput={e => setUsername((e.target as any).value)} placeholder="Username"/>
    </div>
    <div>
      <input defaultValue={password} onInput={e => setPassword((e.target as any).value)} type="password" placeholder="Password"/>
    </div>
    <input type="submit" value="Create an account" disabled={busy}/>
    { errorMessage && <div style={{color: 'red'}}>{errorMessage}</div> }
  </form>
  <div id="signup-error"></div>
</div>;
}
