import React from 'react';

interface LinkButtonProps {
  label: string,
  onClick: any
}

export function LinkButton(props:LinkButtonProps) {
  const {label, onClick} = props;
  return <button className="linkbutton" onClick={onClick}>{label}</button>
}
